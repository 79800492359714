import { default as indexily6rV3EBoMeta } from "/app/pages/certificates/index.vue?macro=true";
import { default as indexZlUF5c0NUNMeta } from "/app/pages/course-areas/[courseArea]/[subjectGroup]/index.vue?macro=true";
import { default as indexFb355T7rQ0Meta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/index.vue?macro=true";
import { default as index6sL1PirIpuMeta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/apply/index.vue?macro=true";
import { default as indexX1DuSrOFfZMeta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/before-you-begin/index.vue?macro=true";
import { default as index52R9ghZWOHMeta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/index.vue?macro=true";
import { default as indexCc3hYtiN64Meta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/review-and-confirm/index.vue?macro=true";
import { default as indexvjvdvEfB0wMeta } from "/app/pages/course-areas/[courseArea]/index.vue?macro=true";
import { default as index0DRWn37sFNMeta } from "/app/pages/course-areas/index.vue?macro=true";
import { default as indextus0RxRpLAMeta } from "/app/pages/course-search/index.vue?macro=true";
import { default as indexAdVDOkEyqxMeta } from "/app/pages/degrees/index.vue?macro=true";
import { default as indexAU4KPYATzCMeta } from "/app/pages/diplomas/index.vue?macro=true";
import { default as indexXa8uIRE1FKMeta } from "/app/pages/fee-free/index.vue?macro=true";
import { default as global_45loaderePVPZaRr7oMeta } from "/app/pages/global-loader.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexHZzFMdGK0QMeta } from "/app/pages/information-sessions/index.vue?macro=true";
import { default as index8jY3dtCosjMeta } from "/app/pages/international/agent-search/index.vue?macro=true";
import { default as indexpS0bNV3ScIMeta } from "/app/pages/international/course-search/index.vue?macro=true";
import { default as indexGLy0JZ8q2GMeta } from "/app/pages/international/courses/[courseSlug]--[courseId]/index.vue?macro=true";
import { default as indexdKo5P0op5cMeta } from "/app/pages/international/index.vue?macro=true";
import { default as indexzUEla03QFlMeta } from "/app/pages/international/locations/index.vue?macro=true";
import { default as indexa8RB5yGhziMeta } from "/app/pages/international/package/[courseName]--[pathwayId]/index.vue?macro=true";
import { default as indexgEpDskiYIFMeta } from "/app/pages/international/subject-areas/index.vue?macro=true";
import { default as indexBZtcOmnV3JMeta } from "/app/pages/locations/[locationSlug]/index.vue?macro=true";
import { default as indexUEX3fKpZliMeta } from "/app/pages/locations/clc/index.vue?macro=true";
import { default as indexILfcf96YXkMeta } from "/app/pages/locations/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as indexXFBPeoKsvoMeta } from "/app/pages/online-courses/index.vue?macro=true";
import { default as indexAfAEtJAmSYMeta } from "/app/pages/short-courses/index.vue?macro=true";
import { default as shortlist9ciz9mo5n8Meta } from "/app/pages/shortlist.vue?macro=true";
import { default as indexBV1zPX3VR7Meta } from "/app/pages/sitemap/index.vue?macro=true";
import { default as sso_45callbackFacPdj5mMpMeta } from "/app/pages/sso-callback.vue?macro=true";
import { default as indexCi05Vc2xGlMeta } from "/app/pages/student-hub/index.vue?macro=true";
import { default as _91id_93vFgOiNfvIrMeta } from "/app/pages/study-seeker/[id].vue?macro=true";
import { default as study_45seekerzMrmKJ1QNqMeta } from "/app/pages/study-seeker.vue?macro=true";
import { default as tailwind_45testVvbDx87XH3Meta } from "/app/pages/tailwind-test.vue?macro=true";
import { default as accordionJTYshAQlQRMeta } from "/app/pages/testing/accordion.vue?macro=true";
import { default as accreditation_45logo5cAEUp5TPjMeta } from "/app/pages/testing/accreditation-logo.vue?macro=true";
import { default as app_45form_45checkboxim1e0BXG55Meta } from "/app/pages/testing/app-form-checkbox.vue?macro=true";
import { default as app_45form_45dateYi1lWa3nYjMeta } from "/app/pages/testing/app-form-date.vue?macro=true";
import { default as app_45form_45dropdownh87b7rwGfUMeta } from "/app/pages/testing/app-form-dropdown.vue?macro=true";
import { default as app_45form_45numberCA1AlF55cmMeta } from "/app/pages/testing/app-form-number.vue?macro=true";
import { default as app_45form_45radiozJpmt8yAJKMeta } from "/app/pages/testing/app-form-radio.vue?macro=true";
import { default as app_45form_45textAfvMksCpdqMeta } from "/app/pages/testing/app-form-text.vue?macro=true";
import { default as app_45formcUmPZ8Y59qMeta } from "/app/pages/testing/app-form.vue?macro=true";
import { default as app_45submitted_45messageQ1nz2UJCVCMeta } from "/app/pages/testing/app-submitted-message.vue?macro=true";
import { default as application_45offering_45cardfHgyfeloNBMeta } from "/app/pages/testing/application-offering-card.vue?macro=true";
import { default as application_45records9ku7AbIc8TMeta } from "/app/pages/testing/application-records.vue?macro=true";
import { default as application_45status_45chipmjTuW5sbzJMeta } from "/app/pages/testing/application-status-chip.vue?macro=true";
import { default as application_45status_45next12vJkls2SbMeta } from "/app/pages/testing/application-status-next.vue?macro=true";
import { default as attribute_45tileaEYVW6UggkMeta } from "/app/pages/testing/attribute-tile.vue?macro=true";
import { default as back_45to_45top_45buttonHzA12q2YhWMeta } from "/app/pages/testing/back-to-top-button.vue?macro=true";
import { default as brand_45list_45groupsatX9EGTk4Meta } from "/app/pages/testing/brand-list-group.vue?macro=true";
import { default as brand_45list_45itemjIokkB8uXCMeta } from "/app/pages/testing/brand-list-item.vue?macro=true";
import { default as brand_45listAAZM5MDdFyMeta } from "/app/pages/testing/brand-list.vue?macro=true";
import { default as breadcrumbsmIUzjWL32fMeta } from "/app/pages/testing/breadcrumbs.vue?macro=true";
import { default as bullet_45list6HyKo5nMzuMeta } from "/app/pages/testing/bullet-list.vue?macro=true";
import { default as campus_45detailsFX1KXX6vrJMeta } from "/app/pages/testing/campus-details.vue?macro=true";
import { default as campus_45facilitiesULD9eR8B7GMeta } from "/app/pages/testing/campus-facilities.vue?macro=true";
import { default as campus_45map_45cardGRh27jDax6Meta } from "/app/pages/testing/campus-map-card.vue?macro=true";
import { default as career_45guidegkib35yEZxMeta } from "/app/pages/testing/career-guide.vue?macro=true";
import { default as chipQEfedeqjQCMeta } from "/app/pages/testing/chip.vue?macro=true";
import { default as contact_45cardVuaIC4NcthMeta } from "/app/pages/testing/contact-card.vue?macro=true";
import { default as content_45areaVsPWvg4UbIMeta } from "/app/pages/testing/content-area.vue?macro=true";
import { default as content_45cardK6UjHS5QqaMeta } from "/app/pages/testing/content-card.vue?macro=true";
import { default as content_45cardsA6Rz5wueijMeta } from "/app/pages/testing/content-cards.vue?macro=true";
import { default as content_45search_45sortdMRZAWDPmkMeta } from "/app/pages/testing/content-search-sort.vue?macro=true";
import { default as content_45sectionMUQioxo4tfMeta } from "/app/pages/testing/content-section.vue?macro=true";
import { default as course_45area_45cardvdffTVhVI9Meta } from "/app/pages/testing/course-area-card.vue?macro=true";
import { default as course_45area_45mobile_45navGJLv5A86PKMeta } from "/app/pages/testing/course-area-mobile-nav.vue?macro=true";
import { default as course_45card_45accordionUi8Mba7m0AMeta } from "/app/pages/testing/course-card-accordion.vue?macro=true";
import { default as course_45cardqjE3X1an3FMeta } from "/app/pages/testing/course-card.vue?macro=true";
import { default as course_45desktop_45nav4b7aO4Si7UMeta } from "/app/pages/testing/course-desktop-nav.vue?macro=true";
import { default as course_45guidezskTr1tc7BMeta } from "/app/pages/testing/course-guide.vue?macro=true";
import { default as course_45mobile_45navCXNzANyFrbMeta } from "/app/pages/testing/course-mobile-nav.vue?macro=true";
import { default as course_45search_45cardmcs3NJeb5vMeta } from "/app/pages/testing/course-search-card.vue?macro=true";
import { default as course_45type_45filters_45mobilerFd9jy5iqlMeta } from "/app/pages/testing/course-type-filters-mobile.vue?macro=true";
import { default as course_45type_45filtersIBpiJPZ5qbMeta } from "/app/pages/testing/course-type-filters.vue?macro=true";
import { default as dashboard_45headeri7T0bQlbaGMeta } from "/app/pages/testing/dashboard-header.vue?macro=true";
import { default as distance_45calculationMgqB9OjDEGMeta } from "/app/pages/testing/distance-calculation.vue?macro=true";
import { default as draft_45applicationyEys1iKa67Meta } from "/app/pages/testing/draft-application.vue?macro=true";
import { default as duration_45filter_45section4RKtarjhXkMeta } from "/app/pages/testing/duration-filter-section.vue?macro=true";
import { default as enrolled_45applicationsQzbIrrkT7cMeta } from "/app/pages/testing/enrolled-applications.vue?macro=true";
import { default as environment_45variables1XYKSCb4dWMeta } from "/app/pages/testing/environment-variables.vue?macro=true";
import { default as error_45application_45cardLymLVfP2H9Meta } from "/app/pages/testing/error-application-card.vue?macro=true";
import { default as fee_45calculator_45containerxNpDK1w1iBMeta } from "/app/pages/testing/fee-calculator-container.vue?macro=true";
import { default as fee_45calculator_45controlsLAtG2lHvikMeta } from "/app/pages/testing/fee-calculator-controls.vue?macro=true";
import { default as fee_45calculator_45eligible9gCS9GnppDMeta } from "/app/pages/testing/fee-calculator-eligible.vue?macro=true";
import { default as fee_45calculator_45not_45eligibleCA4WZNmZcdMeta } from "/app/pages/testing/fee-calculator-not-eligible.vue?macro=true";
import { default as fee_45calculator_45stepper5SqMVr3iGZMeta } from "/app/pages/testing/fee-calculator-stepper.vue?macro=true";
import { default as fee_45detailsDR5SnTEAZMMeta } from "/app/pages/testing/fee-details.vue?macro=true";
import { default as fetch_45errorlhTVBGWDzOMeta } from "/app/pages/testing/fetch-error.vue?macro=true";
import { default as fetch_45loading4mLh8JU0W6Meta } from "/app/pages/testing/fetch-loading.vue?macro=true";
import { default as filter_45chipsYZVtm2i9k3Meta } from "/app/pages/testing/filter-chips.vue?macro=true";
import { default as filter_45dropdownQPQ5m6fDCxMeta } from "/app/pages/testing/filter-dropdown.vue?macro=true";
import { default as filter_45sectione2zR1343KBMeta } from "/app/pages/testing/filter-section.vue?macro=true";
import { default as google_45maps_45autocomplete8FqyWTXn7xMeta } from "/app/pages/testing/google-maps-autocomplete.vue?macro=true";
import { default as hed_45units_45listNAZUQnemhAMeta } from "/app/pages/testing/hed-units-list.vue?macro=true";
import { default as hed_45units_45tabs_45contentiJY45LQ9W4Meta } from "/app/pages/testing/hed-units-tabs-content.vue?macro=true";
import { default as hed_45units_45tabsCokqR3WLg5Meta } from "/app/pages/testing/hed-units-tabs.vue?macro=true";
import { default as help_45buttonsKfYJwT1vX9Meta } from "/app/pages/testing/help-buttons.vue?macro=true";
import { default as hero_45bannerzm5ImdZu2OMeta } from "/app/pages/testing/hero-banner.vue?macro=true";
import { default as icons_45libraryixUFcoYH6MMeta } from "/app/pages/testing/icons-library.vue?macro=true";
import { default as indexoyco8prOz1Meta } from "/app/pages/testing/index.vue?macro=true";
import { default as info_45session_45cardgXZ1jrhYgfMeta } from "/app/pages/testing/info-session-card.vue?macro=true";
import { default as info_45session_45dropdownCxucgqa2WBMeta } from "/app/pages/testing/info-session-dropdown.vue?macro=true";
import { default as info_45session_45filter_45chipsMsEW3y3PDXMeta } from "/app/pages/testing/info-session-filter-chips.vue?macro=true";
import { default as info_45session_45filterstoXvrirWVsMeta } from "/app/pages/testing/info-session-filters.vue?macro=true";
import { default as int_45course_45cardKtn2iz9I4NMeta } from "/app/pages/testing/int-course-card.vue?macro=true";
import { default as int_45dropdownDdsqUodVg1Meta } from "/app/pages/testing/int-dropdown.vue?macro=true";
import { default as int_45filter_45chipseRcJyRtMnNMeta } from "/app/pages/testing/int-filter-chips.vue?macro=true";
import { default as int_45filter6HWMBCZK2DMeta } from "/app/pages/testing/int-filter.vue?macro=true";
import { default as int_45mobile_45filterBTHKqFDu7HMeta } from "/app/pages/testing/int-mobile-filter.vue?macro=true";
import { default as int_45package_45detailse6xPQYLjTvMeta } from "/app/pages/testing/int-package-details.vue?macro=true";
import { default as int_45search_45bar_45slotjmjNQ6XfxIMeta } from "/app/pages/testing/int-search-bar-slot.vue?macro=true";
import { default as intro_45block34knLyWJwgMeta } from "/app/pages/testing/intro-block.vue?macro=true";
import { default as latest_45partners_45listiaRZd8b31qMeta } from "/app/pages/testing/latest-partners-list.vue?macro=true";
import { default as lazy_45imgRHTHB75LzYMeta } from "/app/pages/testing/lazy-img.vue?macro=true";
import { default as lineclamp_45containerDG0zlyBDuYMeta } from "/app/pages/testing/lineclamp-container.vue?macro=true";
import { default as location_45card_45grid9RSxtn0PdjMeta } from "/app/pages/testing/location-card-grid.vue?macro=true";
import { default as location_45cardvhYAwVxLvzMeta } from "/app/pages/testing/location-card.vue?macro=true";
import { default as location_45facilitiesBJovQBEqx4Meta } from "/app/pages/testing/location-facilities.vue?macro=true";
import { default as location_45filter_45sectiontilCd6PJUSMeta } from "/app/pages/testing/location-filter-section.vue?macro=true";
import { default as location_45filterSyw7zIG1jsMeta } from "/app/pages/testing/location-filter.vue?macro=true";
import { default as location_45google_45mapHjwo20dQUgMeta } from "/app/pages/testing/location-google-map.vue?macro=true";
import { default as location_45hero_45bannerm0Yq6Pv3eYMeta } from "/app/pages/testing/location-hero-banner.vue?macro=true";
import { default as location_45popover_45card64UikwB5CHMeta } from "/app/pages/testing/location-popover-card.vue?macro=true";
import { default as location_45storeIfyADnA1fOMeta } from "/app/pages/testing/location-store.vue?macro=true";
import { default as location_45student_45servicesMqaBo6Ev39Meta } from "/app/pages/testing/location-student-services.vue?macro=true";
import { default as loggingPksQk7DKKAMeta } from "/app/pages/testing/logging.vue?macro=true";
import { default as mobile_45filters_45overlayYILslnuyAnMeta } from "/app/pages/testing/mobile-filters-overlay.vue?macro=true";
import { default as modalMsMiUhAnU1Meta } from "/app/pages/testing/modal.vue?macro=true";
import { default as offering_45cta_45button_45externalD5G6ocvMg4Meta } from "/app/pages/testing/offering-cta-button-external.vue?macro=true";
import { default as offering_45cta_45button_45internal3GSaPZrUfOMeta } from "/app/pages/testing/offering-cta-button-internal.vue?macro=true";
import { default as offering_45cta_45buttonb4suEaYud3Meta } from "/app/pages/testing/offering-cta-button.vue?macro=true";
import { default as offering_45detailsADvThk3pO8Meta } from "/app/pages/testing/offering-details.vue?macro=true";
import { default as offering_45list_45cardl4LnmIF4yaMeta } from "/app/pages/testing/offering-list-card.vue?macro=true";
import { default as offering_45listsmDrqdYVEuMeta } from "/app/pages/testing/offering-list.vue?macro=true";
import { default as offering_45searchoFtfJTHIGKMeta } from "/app/pages/testing/offering-search.vue?macro=true";
import { default as offering_45status_45chiphBZzdC4Gn6Meta } from "/app/pages/testing/offering-status-chip.vue?macro=true";
import { default as offering_45statusVkAtQrgRgiMeta } from "/app/pages/testing/offering-status.vue?macro=true";
import { default as offering_45summary_45tileNXPyVTtEeLMeta } from "/app/pages/testing/offering-summary-tile.vue?macro=true";
import { default as page_45header_45buttonsyefggFospCMeta } from "/app/pages/testing/page-header-buttons.vue?macro=true";
import { default as page_45headerwXNl5ZdFkcMeta } from "/app/pages/testing/page-header.vue?macro=true";
import { default as paginationjvilQAjvEdMeta } from "/app/pages/testing/pagination.vue?macro=true";
import { default as progress_45barHqvIPUmkwOMeta } from "/app/pages/testing/progress-bar.vue?macro=true";
import { default as promotion_45card2yVxCXTj8XMeta } from "/app/pages/testing/promotion-card.vue?macro=true";
import { default as promotionsNuypSL3xMpMeta } from "/app/pages/testing/promotions.vue?macro=true";
import { default as resourceswWBdlGOYqMMeta } from "/app/pages/testing/resources.vue?macro=true";
import { default as search_45autocompleteBIIP2vHTJhMeta } from "/app/pages/testing/search-autocomplete.vue?macro=true";
import { default as search_45filters4lGLWGwwQCMeta } from "/app/pages/testing/search-filters.vue?macro=true";
import { default as side_45nav6NTUJGkYVSMeta } from "/app/pages/testing/side-nav.vue?macro=true";
import { default as skeleton_45boxNfWMnt9UGDMeta } from "/app/pages/testing/skeleton-box.vue?macro=true";
import { default as spinnerYR9XddG8PuMeta } from "/app/pages/testing/spinner.vue?macro=true";
import { default as spy_45scrollBHsyVXfQiPMeta } from "/app/pages/testing/spy-scroll.vue?macro=true";
import { default as static_45card_45group5IfgnJxfbLMeta } from "/app/pages/testing/static-card-group.vue?macro=true";
import { default as static_45cardB0ygfUDkAGMeta } from "/app/pages/testing/static-card.vue?macro=true";
import { default as stepper_45checkboxJS24gykTaeMeta } from "/app/pages/testing/stepper-checkbox.vue?macro=true";
import { default as stepper_45dropdown2C4yqLur6JMeta } from "/app/pages/testing/stepper-dropdown.vue?macro=true";
import { default as stepper_45numberAXnyBUSf24Meta } from "/app/pages/testing/stepper-number.vue?macro=true";
import { default as stepper_45postcodekkM4kBIWknMeta } from "/app/pages/testing/stepper-postcode.vue?macro=true";
import { default as stepper_45radioPiZ12fAVA5Meta } from "/app/pages/testing/stepper-radio.vue?macro=true";
import { default as study_45seeker_45controlsEbneIbxVpDMeta } from "/app/pages/testing/study-seeker-controls.vue?macro=true";
import { default as study_45seeker_45headerm9NEjAH6fBMeta } from "/app/pages/testing/study-seeker-header.vue?macro=true";
import { default as study_45seeker_45list_45itemON1MyKpdyrMeta } from "/app/pages/testing/study-seeker-list-item.vue?macro=true";
import { default as study_45seeker_45results_45listg1QEl4LFYzMeta } from "/app/pages/testing/study-seeker-results-list.vue?macro=true";
import { default as study_45seeker_45resultspqdmxNKicyMeta } from "/app/pages/testing/study-seeker-results.vue?macro=true";
import { default as study_45seeker_45stepper2QKuHkiR9jMeta } from "/app/pages/testing/study-seeker-stepper.vue?macro=true";
import { default as study_45seeker_45storeoqtiAVLYUNMeta } from "/app/pages/testing/study-seeker-store.vue?macro=true";
import { default as subjectgroup_45sidenavWtZZGNkd4GMeta } from "/app/pages/testing/subjectgroup-sidenav.vue?macro=true";
import { default as subsidy_45detailsHCEdWwd8PxMeta } from "/app/pages/testing/subsidy-details.vue?macro=true";
import { default as tableRw5NBSlkx5Meta } from "/app/pages/testing/table.vue?macro=true";
import { default as tabs_45genericvyhcr7feR3Meta } from "/app/pages/testing/tabs-generic.vue?macro=true";
import { default as tabs_45slotFiPYTTMIeGMeta } from "/app/pages/testing/tabs-slot.vue?macro=true";
import { default as tafe_45button5BfSGe0wozMeta } from "/app/pages/testing/tafe-button.vue?macro=true";
import { default as tafe_45linkmy2dAAAooMMeta } from "/app/pages/testing/tafe-link.vue?macro=true";
import { default as tafe_45tilelkUmHhDnBwMeta } from "/app/pages/testing/tafe-tile.vue?macro=true";
import { default as tafe_45tileshkPQbzeDXfMeta } from "/app/pages/testing/tafe-tiles.vue?macro=true";
import { default as testimonial_45cardyVx1bqVrojMeta } from "/app/pages/testing/testimonial-card.vue?macro=true";
import { default as testimonial_45cards6aPWw9ol1AMeta } from "/app/pages/testing/testimonial-cards.vue?macro=true";
import { default as tooltipVGjc4Yar6QMeta } from "/app/pages/testing/tooltip.vue?macro=true";
import { default as transition_45groupWdP7voFFVIMeta } from "/app/pages/testing/transition-group.vue?macro=true";
import { default as units_45listKqMClVHytiMeta } from "/app/pages/testing/units-list.vue?macro=true";
import { default as units_45tabs_45contentF5rKPANIthMeta } from "/app/pages/testing/units-tabs-content.vue?macro=true";
import { default as units_45tabs4RM6q0zdqHMeta } from "/app/pages/testing/units-tabs.vue?macro=true";
import { default as validation_45messageDm8yha439IMeta } from "/app/pages/testing/validation-message.vue?macro=true";
import { default as videoq5xRR2mSlmMeta } from "/app/pages/testing/video.vue?macro=true";
import { default as vsl_45modalg4Cp4PsvhAMeta } from "/app/pages/testing/vsl-modal.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "Certificates",
    path: "/certificates",
    meta: indexily6rV3EBoMeta || {},
    component: () => import("/app/pages/certificates/index.vue")
  },
  {
    name: "course-areas-courseArea-subjectGroup",
    path: "/course-areas/:courseArea()/:subjectGroup()",
    component: () => import("/app/pages/course-areas/[courseArea]/[subjectGroup]/index.vue")
  },
  {
    name: "Course",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()",
    meta: indexFb355T7rQ0Meta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/index.vue")
  },
  {
    name: "OfferingApply",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()/apply",
    meta: index6sL1PirIpuMeta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/apply/index.vue")
  },
  {
    name: "OfferingBeforeYouBegin",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()/before-you-begin",
    meta: indexX1DuSrOFfZMeta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/before-you-begin/index.vue")
  },
  {
    name: "Offering",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()",
    meta: index52R9ghZWOHMeta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/index.vue")
  },
  {
    name: "OfferingReviewAndConfirm",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()/review-and-confirm",
    meta: indexCc3hYtiN64Meta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/review-and-confirm/index.vue")
  },
  {
    name: "course-areas-courseArea",
    path: "/course-areas/:courseArea()",
    component: () => import("/app/pages/course-areas/[courseArea]/index.vue")
  },
  {
    name: "course-areas",
    path: "/course-areas",
    component: () => import("/app/pages/course-areas/index.vue")
  },
  {
    name: "course-search",
    path: "/course-search",
    component: () => import("/app/pages/course-search/index.vue")
  },
  {
    name: "Degrees",
    path: "/degrees",
    meta: indexAdVDOkEyqxMeta || {},
    component: () => import("/app/pages/degrees/index.vue")
  },
  {
    name: "Diplomas",
    path: "/diplomas",
    meta: indexAU4KPYATzCMeta || {},
    component: () => import("/app/pages/diplomas/index.vue")
  },
  {
    name: "FeeFree",
    path: "/fee-free",
    meta: indexXa8uIRE1FKMeta || {},
    component: () => import("/app/pages/fee-free/index.vue")
  },
  {
    name: "global-loader",
    path: "/global-loader",
    component: () => import("/app/pages/global-loader.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "information-sessions",
    path: "/information-sessions",
    component: () => import("/app/pages/information-sessions/index.vue")
  },
  {
    name: "international-agent-search",
    path: "/international/agent-search",
    meta: index8jY3dtCosjMeta || {},
    component: () => import("/app/pages/international/agent-search/index.vue")
  },
  {
    name: "international-search",
    path: "/international/course-search",
    meta: indexpS0bNV3ScIMeta || {},
    component: () => import("/app/pages/international/course-search/index.vue")
  },
  {
    name: "IntCourse",
    path: "/international/courses/:courseSlug()--:courseId()",
    meta: indexGLy0JZ8q2GMeta || {},
    component: () => import("/app/pages/international/courses/[courseSlug]--[courseId]/index.vue")
  },
  {
    name: "international",
    path: "/international",
    meta: indexdKo5P0op5cMeta || {},
    component: () => import("/app/pages/international/index.vue")
  },
  {
    name: "international-locations",
    path: "/international/locations",
    meta: indexzUEla03QFlMeta || {},
    component: () => import("/app/pages/international/locations/index.vue")
  },
  {
    name: "IntPathway",
    path: "/international/package/:courseName()--:pathwayId()",
    meta: indexa8RB5yGhziMeta || {},
    component: () => import("/app/pages/international/package/[courseName]--[pathwayId]/index.vue")
  },
  {
    name: "IntCourseAreas",
    path: "/international/subject-areas",
    meta: indexgEpDskiYIFMeta || {},
    component: () => import("/app/pages/international/subject-areas/index.vue")
  },
  {
    name: "LocationPage",
    path: "/locations/:locationSlug()",
    meta: indexBZtcOmnV3JMeta || {},
    component: () => import("/app/pages/locations/[locationSlug]/index.vue")
  },
  {
    name: "connected-learning-centres",
    path: "/locations/clc",
    meta: indexUEX3fKpZliMeta || {},
    component: () => import("/app/pages/locations/clc/index.vue")
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/app/pages/locations/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "OnlineCourses",
    path: "/online-courses",
    meta: indexXFBPeoKsvoMeta || {},
    component: () => import("/app/pages/online-courses/index.vue")
  },
  {
    name: "ShortCourses",
    path: "/short-courses",
    meta: indexAfAEtJAmSYMeta || {},
    component: () => import("/app/pages/short-courses/index.vue")
  },
  {
    name: "Shortlist",
    path: "/shortlist",
    meta: shortlist9ciz9mo5n8Meta || {},
    component: () => import("/app/pages/shortlist.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/app/pages/sitemap/index.vue")
  },
  {
    name: "sso-callback",
    path: "/sso-callback",
    component: () => import("/app/pages/sso-callback.vue")
  },
  {
    name: "StudentHub",
    path: "/student-hub",
    meta: indexCi05Vc2xGlMeta || {},
    component: () => import("/app/pages/student-hub/index.vue")
  },
  {
    name: "study-seeker",
    path: "/study-seeker",
    meta: study_45seekerzMrmKJ1QNqMeta || {},
    component: () => import("/app/pages/study-seeker.vue"),
    children: [
  {
    name: "study-seeker-id",
    path: ":id()",
    meta: _91id_93vFgOiNfvIrMeta || {},
    component: () => import("/app/pages/study-seeker/[id].vue")
  }
]
  },
  {
    name: "tailwind-test",
    path: "/tailwind-test",
    component: () => import("/app/pages/tailwind-test.vue")
  },
  {
    name: "testing-accordion",
    path: "/testing/accordion",
    component: () => import("/app/pages/testing/accordion.vue")
  },
  {
    name: "testing-accreditation-logo",
    path: "/testing/accreditation-logo",
    component: () => import("/app/pages/testing/accreditation-logo.vue")
  },
  {
    name: "testing-app-form-checkbox",
    path: "/testing/app-form-checkbox",
    component: () => import("/app/pages/testing/app-form-checkbox.vue")
  },
  {
    name: "testing-app-form-date",
    path: "/testing/app-form-date",
    component: () => import("/app/pages/testing/app-form-date.vue")
  },
  {
    name: "testing-app-form-dropdown",
    path: "/testing/app-form-dropdown",
    component: () => import("/app/pages/testing/app-form-dropdown.vue")
  },
  {
    name: "testing-app-form-number",
    path: "/testing/app-form-number",
    component: () => import("/app/pages/testing/app-form-number.vue")
  },
  {
    name: "testing-app-form-radio",
    path: "/testing/app-form-radio",
    component: () => import("/app/pages/testing/app-form-radio.vue")
  },
  {
    name: "testing-app-form-text",
    path: "/testing/app-form-text",
    component: () => import("/app/pages/testing/app-form-text.vue")
  },
  {
    name: "testing-app-form",
    path: "/testing/app-form",
    component: () => import("/app/pages/testing/app-form.vue")
  },
  {
    name: "testing-app-submitted-message",
    path: "/testing/app-submitted-message",
    component: () => import("/app/pages/testing/app-submitted-message.vue")
  },
  {
    name: "testing-application-offering-card",
    path: "/testing/application-offering-card",
    component: () => import("/app/pages/testing/application-offering-card.vue")
  },
  {
    name: "testing-application-records",
    path: "/testing/application-records",
    component: () => import("/app/pages/testing/application-records.vue")
  },
  {
    name: "testing-application-status-chip",
    path: "/testing/application-status-chip",
    component: () => import("/app/pages/testing/application-status-chip.vue")
  },
  {
    name: "testing-application-status-next",
    path: "/testing/application-status-next",
    component: () => import("/app/pages/testing/application-status-next.vue")
  },
  {
    name: "testing-attribute-tile",
    path: "/testing/attribute-tile",
    component: () => import("/app/pages/testing/attribute-tile.vue")
  },
  {
    name: "testing-back-to-top-button",
    path: "/testing/back-to-top-button",
    component: () => import("/app/pages/testing/back-to-top-button.vue")
  },
  {
    name: "testing-brand-list-group",
    path: "/testing/brand-list-group",
    component: () => import("/app/pages/testing/brand-list-group.vue")
  },
  {
    name: "testing-brand-list-item",
    path: "/testing/brand-list-item",
    component: () => import("/app/pages/testing/brand-list-item.vue")
  },
  {
    name: "testing-brand-list",
    path: "/testing/brand-list",
    component: () => import("/app/pages/testing/brand-list.vue")
  },
  {
    name: "testing-breadcrumbs",
    path: "/testing/breadcrumbs",
    component: () => import("/app/pages/testing/breadcrumbs.vue")
  },
  {
    name: "testing-bullet-list",
    path: "/testing/bullet-list",
    component: () => import("/app/pages/testing/bullet-list.vue")
  },
  {
    name: "testing-campus-details",
    path: "/testing/campus-details",
    component: () => import("/app/pages/testing/campus-details.vue")
  },
  {
    name: "testing-campus-facilities",
    path: "/testing/campus-facilities",
    component: () => import("/app/pages/testing/campus-facilities.vue")
  },
  {
    name: "testing-campus-map-card",
    path: "/testing/campus-map-card",
    component: () => import("/app/pages/testing/campus-map-card.vue")
  },
  {
    name: "testing-career-guide",
    path: "/testing/career-guide",
    component: () => import("/app/pages/testing/career-guide.vue")
  },
  {
    name: "testing-chip",
    path: "/testing/chip",
    component: () => import("/app/pages/testing/chip.vue")
  },
  {
    name: "testing-contact-card",
    path: "/testing/contact-card",
    component: () => import("/app/pages/testing/contact-card.vue")
  },
  {
    name: "testing-content-area",
    path: "/testing/content-area",
    component: () => import("/app/pages/testing/content-area.vue")
  },
  {
    name: "testing-content-card",
    path: "/testing/content-card",
    component: () => import("/app/pages/testing/content-card.vue")
  },
  {
    name: "testing-content-cards",
    path: "/testing/content-cards",
    component: () => import("/app/pages/testing/content-cards.vue")
  },
  {
    name: "testing-content-search-sort",
    path: "/testing/content-search-sort",
    component: () => import("/app/pages/testing/content-search-sort.vue")
  },
  {
    name: "testing-content-section",
    path: "/testing/content-section",
    component: () => import("/app/pages/testing/content-section.vue")
  },
  {
    name: "testing-course-area-card",
    path: "/testing/course-area-card",
    component: () => import("/app/pages/testing/course-area-card.vue")
  },
  {
    name: "testing-course-area-mobile-nav",
    path: "/testing/course-area-mobile-nav",
    component: () => import("/app/pages/testing/course-area-mobile-nav.vue")
  },
  {
    name: "testing-course-card-accordion",
    path: "/testing/course-card-accordion",
    component: () => import("/app/pages/testing/course-card-accordion.vue")
  },
  {
    name: "testing-course-card",
    path: "/testing/course-card",
    component: () => import("/app/pages/testing/course-card.vue")
  },
  {
    name: "testing-course-desktop-nav",
    path: "/testing/course-desktop-nav",
    component: () => import("/app/pages/testing/course-desktop-nav.vue")
  },
  {
    name: "testing-course-guide",
    path: "/testing/course-guide",
    component: () => import("/app/pages/testing/course-guide.vue")
  },
  {
    name: "testing-course-mobile-nav",
    path: "/testing/course-mobile-nav",
    component: () => import("/app/pages/testing/course-mobile-nav.vue")
  },
  {
    name: "testing-course-search-card",
    path: "/testing/course-search-card",
    component: () => import("/app/pages/testing/course-search-card.vue")
  },
  {
    name: "testing-course-type-filters-mobile",
    path: "/testing/course-type-filters-mobile",
    component: () => import("/app/pages/testing/course-type-filters-mobile.vue")
  },
  {
    name: "testing-course-type-filters",
    path: "/testing/course-type-filters",
    component: () => import("/app/pages/testing/course-type-filters.vue")
  },
  {
    name: "testing-dashboard-header",
    path: "/testing/dashboard-header",
    component: () => import("/app/pages/testing/dashboard-header.vue")
  },
  {
    name: "testing-distance-calculation",
    path: "/testing/distance-calculation",
    component: () => import("/app/pages/testing/distance-calculation.vue")
  },
  {
    name: "testing-draft-application",
    path: "/testing/draft-application",
    component: () => import("/app/pages/testing/draft-application.vue")
  },
  {
    name: "testing-duration-filter-section",
    path: "/testing/duration-filter-section",
    component: () => import("/app/pages/testing/duration-filter-section.vue")
  },
  {
    name: "testing-enrolled-applications",
    path: "/testing/enrolled-applications",
    component: () => import("/app/pages/testing/enrolled-applications.vue")
  },
  {
    name: "testing-environment-variables",
    path: "/testing/environment-variables",
    component: () => import("/app/pages/testing/environment-variables.vue")
  },
  {
    name: "testing-error-application-card",
    path: "/testing/error-application-card",
    component: () => import("/app/pages/testing/error-application-card.vue")
  },
  {
    name: "testing-fee-calculator-container",
    path: "/testing/fee-calculator-container",
    component: () => import("/app/pages/testing/fee-calculator-container.vue")
  },
  {
    name: "testing-fee-calculator-controls",
    path: "/testing/fee-calculator-controls",
    component: () => import("/app/pages/testing/fee-calculator-controls.vue")
  },
  {
    name: "testing-fee-calculator-eligible",
    path: "/testing/fee-calculator-eligible",
    component: () => import("/app/pages/testing/fee-calculator-eligible.vue")
  },
  {
    name: "testing-fee-calculator-not-eligible",
    path: "/testing/fee-calculator-not-eligible",
    component: () => import("/app/pages/testing/fee-calculator-not-eligible.vue")
  },
  {
    name: "testing-fee-calculator-stepper",
    path: "/testing/fee-calculator-stepper",
    component: () => import("/app/pages/testing/fee-calculator-stepper.vue")
  },
  {
    name: "testing-fee-details",
    path: "/testing/fee-details",
    component: () => import("/app/pages/testing/fee-details.vue")
  },
  {
    name: "testing-fetch-error",
    path: "/testing/fetch-error",
    component: () => import("/app/pages/testing/fetch-error.vue")
  },
  {
    name: "testing-fetch-loading",
    path: "/testing/fetch-loading",
    component: () => import("/app/pages/testing/fetch-loading.vue")
  },
  {
    name: "testing-filter-chips",
    path: "/testing/filter-chips",
    component: () => import("/app/pages/testing/filter-chips.vue")
  },
  {
    name: "testing-filter-dropdown",
    path: "/testing/filter-dropdown",
    component: () => import("/app/pages/testing/filter-dropdown.vue")
  },
  {
    name: "testing-filter-section",
    path: "/testing/filter-section",
    component: () => import("/app/pages/testing/filter-section.vue")
  },
  {
    name: "testing-google-maps-autocomplete",
    path: "/testing/google-maps-autocomplete",
    component: () => import("/app/pages/testing/google-maps-autocomplete.vue")
  },
  {
    name: "testing-hed-units-list",
    path: "/testing/hed-units-list",
    component: () => import("/app/pages/testing/hed-units-list.vue")
  },
  {
    name: "testing-hed-units-tabs-content",
    path: "/testing/hed-units-tabs-content",
    component: () => import("/app/pages/testing/hed-units-tabs-content.vue")
  },
  {
    name: "testing-hed-units-tabs",
    path: "/testing/hed-units-tabs",
    component: () => import("/app/pages/testing/hed-units-tabs.vue")
  },
  {
    name: "testing-help-buttons",
    path: "/testing/help-buttons",
    component: () => import("/app/pages/testing/help-buttons.vue")
  },
  {
    name: "testing-hero-banner",
    path: "/testing/hero-banner",
    component: () => import("/app/pages/testing/hero-banner.vue")
  },
  {
    name: "testing-icons-library",
    path: "/testing/icons-library",
    component: () => import("/app/pages/testing/icons-library.vue")
  },
  {
    name: "testing",
    path: "/testing",
    component: () => import("/app/pages/testing/index.vue")
  },
  {
    name: "testing-info-session-card",
    path: "/testing/info-session-card",
    component: () => import("/app/pages/testing/info-session-card.vue")
  },
  {
    name: "testing-info-session-dropdown",
    path: "/testing/info-session-dropdown",
    component: () => import("/app/pages/testing/info-session-dropdown.vue")
  },
  {
    name: "testing-info-session-filter-chips",
    path: "/testing/info-session-filter-chips",
    component: () => import("/app/pages/testing/info-session-filter-chips.vue")
  },
  {
    name: "testing-info-session-filters",
    path: "/testing/info-session-filters",
    component: () => import("/app/pages/testing/info-session-filters.vue")
  },
  {
    name: "testing-int-course-card",
    path: "/testing/int-course-card",
    component: () => import("/app/pages/testing/int-course-card.vue")
  },
  {
    name: "testing-int-dropdown",
    path: "/testing/int-dropdown",
    component: () => import("/app/pages/testing/int-dropdown.vue")
  },
  {
    name: "testing-int-filter-chips",
    path: "/testing/int-filter-chips",
    component: () => import("/app/pages/testing/int-filter-chips.vue")
  },
  {
    name: "testing-int-filter",
    path: "/testing/int-filter",
    component: () => import("/app/pages/testing/int-filter.vue")
  },
  {
    name: "testing-int-mobile-filter",
    path: "/testing/int-mobile-filter",
    component: () => import("/app/pages/testing/int-mobile-filter.vue")
  },
  {
    name: "testing-int-package-details",
    path: "/testing/int-package-details",
    component: () => import("/app/pages/testing/int-package-details.vue")
  },
  {
    name: "testing-int-search-bar-slot",
    path: "/testing/int-search-bar-slot",
    component: () => import("/app/pages/testing/int-search-bar-slot.vue")
  },
  {
    name: "testing-intro-block",
    path: "/testing/intro-block",
    component: () => import("/app/pages/testing/intro-block.vue")
  },
  {
    name: "testing-latest-partners-list",
    path: "/testing/latest-partners-list",
    component: () => import("/app/pages/testing/latest-partners-list.vue")
  },
  {
    name: "testing-lazy-img",
    path: "/testing/lazy-img",
    component: () => import("/app/pages/testing/lazy-img.vue")
  },
  {
    name: "testing-lineclamp-container",
    path: "/testing/lineclamp-container",
    component: () => import("/app/pages/testing/lineclamp-container.vue")
  },
  {
    name: "testing-location-card-grid",
    path: "/testing/location-card-grid",
    component: () => import("/app/pages/testing/location-card-grid.vue")
  },
  {
    name: "testing-location-card",
    path: "/testing/location-card",
    component: () => import("/app/pages/testing/location-card.vue")
  },
  {
    name: "testing-location-facilities",
    path: "/testing/location-facilities",
    component: () => import("/app/pages/testing/location-facilities.vue")
  },
  {
    name: "testing-location-filter-section",
    path: "/testing/location-filter-section",
    component: () => import("/app/pages/testing/location-filter-section.vue")
  },
  {
    name: "testing-location-filter",
    path: "/testing/location-filter",
    component: () => import("/app/pages/testing/location-filter.vue")
  },
  {
    name: "testing-location-google-map",
    path: "/testing/location-google-map",
    component: () => import("/app/pages/testing/location-google-map.vue")
  },
  {
    name: "testing-location-hero-banner",
    path: "/testing/location-hero-banner",
    component: () => import("/app/pages/testing/location-hero-banner.vue")
  },
  {
    name: "testing-location-popover-card",
    path: "/testing/location-popover-card",
    component: () => import("/app/pages/testing/location-popover-card.vue")
  },
  {
    name: "testing-location-store",
    path: "/testing/location-store",
    component: () => import("/app/pages/testing/location-store.vue")
  },
  {
    name: "testing-location-student-services",
    path: "/testing/location-student-services",
    component: () => import("/app/pages/testing/location-student-services.vue")
  },
  {
    name: "testing-logging",
    path: "/testing/logging",
    component: () => import("/app/pages/testing/logging.vue")
  },
  {
    name: "testing-mobile-filters-overlay",
    path: "/testing/mobile-filters-overlay",
    component: () => import("/app/pages/testing/mobile-filters-overlay.vue")
  },
  {
    name: "testing-modal",
    path: "/testing/modal",
    component: () => import("/app/pages/testing/modal.vue")
  },
  {
    name: "testing-offering-cta-button-external",
    path: "/testing/offering-cta-button-external",
    component: () => import("/app/pages/testing/offering-cta-button-external.vue")
  },
  {
    name: "testing-offering-cta-button-internal",
    path: "/testing/offering-cta-button-internal",
    component: () => import("/app/pages/testing/offering-cta-button-internal.vue")
  },
  {
    name: "testing-offering-cta-button",
    path: "/testing/offering-cta-button",
    component: () => import("/app/pages/testing/offering-cta-button.vue")
  },
  {
    name: "testing-offering-details",
    path: "/testing/offering-details",
    component: () => import("/app/pages/testing/offering-details.vue")
  },
  {
    name: "testing-offering-list-card",
    path: "/testing/offering-list-card",
    component: () => import("/app/pages/testing/offering-list-card.vue")
  },
  {
    name: "testing-offering-list",
    path: "/testing/offering-list",
    component: () => import("/app/pages/testing/offering-list.vue")
  },
  {
    name: "testing-offering-search",
    path: "/testing/offering-search",
    component: () => import("/app/pages/testing/offering-search.vue")
  },
  {
    name: "testing-offering-status-chip",
    path: "/testing/offering-status-chip",
    component: () => import("/app/pages/testing/offering-status-chip.vue")
  },
  {
    name: "testing-offering-status",
    path: "/testing/offering-status",
    component: () => import("/app/pages/testing/offering-status.vue")
  },
  {
    name: "testing-offering-summary-tile",
    path: "/testing/offering-summary-tile",
    component: () => import("/app/pages/testing/offering-summary-tile.vue")
  },
  {
    name: "testing-page-header-buttons",
    path: "/testing/page-header-buttons",
    component: () => import("/app/pages/testing/page-header-buttons.vue")
  },
  {
    name: "testing-page-header",
    path: "/testing/page-header",
    component: () => import("/app/pages/testing/page-header.vue")
  },
  {
    name: "testing-pagination",
    path: "/testing/pagination",
    component: () => import("/app/pages/testing/pagination.vue")
  },
  {
    name: "testing-progress-bar",
    path: "/testing/progress-bar",
    component: () => import("/app/pages/testing/progress-bar.vue")
  },
  {
    name: "testing-promotion-card",
    path: "/testing/promotion-card",
    component: () => import("/app/pages/testing/promotion-card.vue")
  },
  {
    name: "testing-promotions",
    path: "/testing/promotions",
    component: () => import("/app/pages/testing/promotions.vue")
  },
  {
    name: "testing-resources",
    path: "/testing/resources",
    component: () => import("/app/pages/testing/resources.vue")
  },
  {
    name: "testing-search-autocomplete",
    path: "/testing/search-autocomplete",
    component: () => import("/app/pages/testing/search-autocomplete.vue")
  },
  {
    name: "testing-search-filters",
    path: "/testing/search-filters",
    component: () => import("/app/pages/testing/search-filters.vue")
  },
  {
    name: "testing-side-nav",
    path: "/testing/side-nav",
    component: () => import("/app/pages/testing/side-nav.vue")
  },
  {
    name: "testing-skeleton-box",
    path: "/testing/skeleton-box",
    component: () => import("/app/pages/testing/skeleton-box.vue")
  },
  {
    name: "testing-spinner",
    path: "/testing/spinner",
    component: () => import("/app/pages/testing/spinner.vue")
  },
  {
    name: "testing-spy-scroll",
    path: "/testing/spy-scroll",
    component: () => import("/app/pages/testing/spy-scroll.vue")
  },
  {
    name: "testing-static-card-group",
    path: "/testing/static-card-group",
    component: () => import("/app/pages/testing/static-card-group.vue")
  },
  {
    name: "testing-static-card",
    path: "/testing/static-card",
    component: () => import("/app/pages/testing/static-card.vue")
  },
  {
    name: "testing-stepper-checkbox",
    path: "/testing/stepper-checkbox",
    component: () => import("/app/pages/testing/stepper-checkbox.vue")
  },
  {
    name: "testing-stepper-dropdown",
    path: "/testing/stepper-dropdown",
    component: () => import("/app/pages/testing/stepper-dropdown.vue")
  },
  {
    name: "testing-stepper-number",
    path: "/testing/stepper-number",
    component: () => import("/app/pages/testing/stepper-number.vue")
  },
  {
    name: "testing-stepper-postcode",
    path: "/testing/stepper-postcode",
    component: () => import("/app/pages/testing/stepper-postcode.vue")
  },
  {
    name: "testing-stepper-radio",
    path: "/testing/stepper-radio",
    component: () => import("/app/pages/testing/stepper-radio.vue")
  },
  {
    name: "testing-study-seeker-controls",
    path: "/testing/study-seeker-controls",
    component: () => import("/app/pages/testing/study-seeker-controls.vue")
  },
  {
    name: "testing-study-seeker-header",
    path: "/testing/study-seeker-header",
    component: () => import("/app/pages/testing/study-seeker-header.vue")
  },
  {
    name: "testing-study-seeker-list-item",
    path: "/testing/study-seeker-list-item",
    component: () => import("/app/pages/testing/study-seeker-list-item.vue")
  },
  {
    name: "testing-study-seeker-results-list",
    path: "/testing/study-seeker-results-list",
    component: () => import("/app/pages/testing/study-seeker-results-list.vue")
  },
  {
    name: "testing-study-seeker-results",
    path: "/testing/study-seeker-results",
    component: () => import("/app/pages/testing/study-seeker-results.vue")
  },
  {
    name: "testing-study-seeker-stepper",
    path: "/testing/study-seeker-stepper",
    component: () => import("/app/pages/testing/study-seeker-stepper.vue")
  },
  {
    name: "testing-study-seeker-store",
    path: "/testing/study-seeker-store",
    component: () => import("/app/pages/testing/study-seeker-store.vue")
  },
  {
    name: "testing-subjectgroup-sidenav",
    path: "/testing/subjectgroup-sidenav",
    component: () => import("/app/pages/testing/subjectgroup-sidenav.vue")
  },
  {
    name: "testing-subsidy-details",
    path: "/testing/subsidy-details",
    component: () => import("/app/pages/testing/subsidy-details.vue")
  },
  {
    name: "testing-table",
    path: "/testing/table",
    component: () => import("/app/pages/testing/table.vue")
  },
  {
    name: "testing-tabs-generic",
    path: "/testing/tabs-generic",
    component: () => import("/app/pages/testing/tabs-generic.vue")
  },
  {
    name: "testing-tabs-slot",
    path: "/testing/tabs-slot",
    component: () => import("/app/pages/testing/tabs-slot.vue")
  },
  {
    name: "testing-tafe-button",
    path: "/testing/tafe-button",
    component: () => import("/app/pages/testing/tafe-button.vue")
  },
  {
    name: "testing-tafe-link",
    path: "/testing/tafe-link",
    component: () => import("/app/pages/testing/tafe-link.vue")
  },
  {
    name: "testing-tafe-tile",
    path: "/testing/tafe-tile",
    component: () => import("/app/pages/testing/tafe-tile.vue")
  },
  {
    name: "testing-tafe-tiles",
    path: "/testing/tafe-tiles",
    component: () => import("/app/pages/testing/tafe-tiles.vue")
  },
  {
    name: "testing-testimonial-card",
    path: "/testing/testimonial-card",
    component: () => import("/app/pages/testing/testimonial-card.vue")
  },
  {
    name: "testing-testimonial-cards",
    path: "/testing/testimonial-cards",
    component: () => import("/app/pages/testing/testimonial-cards.vue")
  },
  {
    name: "testing-tooltip",
    path: "/testing/tooltip",
    component: () => import("/app/pages/testing/tooltip.vue")
  },
  {
    name: "testing-transition-group",
    path: "/testing/transition-group",
    component: () => import("/app/pages/testing/transition-group.vue")
  },
  {
    name: "testing-units-list",
    path: "/testing/units-list",
    component: () => import("/app/pages/testing/units-list.vue")
  },
  {
    name: "testing-units-tabs-content",
    path: "/testing/units-tabs-content",
    component: () => import("/app/pages/testing/units-tabs-content.vue")
  },
  {
    name: "testing-units-tabs",
    path: "/testing/units-tabs",
    component: () => import("/app/pages/testing/units-tabs.vue")
  },
  {
    name: "testing-validation-message",
    path: "/testing/validation-message",
    component: () => import("/app/pages/testing/validation-message.vue")
  },
  {
    name: "testing-video",
    path: "/testing/video",
    component: () => import("/app/pages/testing/video.vue")
  },
  {
    name: "testing-vsl-modal",
    path: "/testing/vsl-modal",
    component: () => import("/app/pages/testing/vsl-modal.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fee-free-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fee-free-short-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/clc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/locations/qr/armidale-beardy-steet",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/locations/qr/broken-hill-argent-steet",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/study/locations",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/hosp-hotel",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/athlete-education-pathway",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/business-training",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/business-training/equifax",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/centresofexcellence",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/centres-of-excellence",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/coe",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cyber-academy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/essential-eight",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/event-tourism",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/miniwoolies",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/travel-hosp-events",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/visitoreconomy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/worldskills",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/worldskills-au",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/wdo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/amep",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/adult-migrant-english-program-amep",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/adult-migrant-english-program-amep/amep-distance-learning",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/eartrain",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ecec-professional-development-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ecec-professional-learning-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/gili-awards",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/iprowd",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/adult-migrant-english-program-amep/pep",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/pathways/ranks-to-recognition",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/renewable-energy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/skillmax",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/see",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/see",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/switch",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/virtualopenday",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/amepvtp",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/adult-migrant-english-program-amep/amep-volunteer-tutor-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/vtp",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/womeninbusiness",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/mature-age-workers",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/ramsgate-rsl-memorial-club-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/ricoh-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/search",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/women-in-finance-and-economics-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/scholarships/andy-lindsay-memorial-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/anthony-carter-memorial",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/barker-maritime",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/barrington-raymund-roberts-memorial-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/bean-counters-achievement-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/bean-counters-encouragement-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/brett-graham-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/carol-duval-memorial-award-for-painting",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/central-coast-campus-friends-awards",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/choosi-futures-grant-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/cultural-diversity",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/destination-australia-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/education-support",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/equity",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/nsw-farmers-association-agriculture-and-wool-classing-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/henry-harding-memorial-encouragement-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/hunter-tafe-foundation",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/hunter-tafe-foundation/board-members",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/hunter-tafe-foundation/honour-roll",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/hunter-tafe-foundation/privacy-statement",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/hunter-tafe-foundation-scholarships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/josh-woolfson-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/kay-peno-encouragement-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/legacy-club-of-wagga-wagga",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/lighthouse-club-australia-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/lords-taverners-grants-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/moreen-edith-fashion",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/neville-sawyer-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/newcastle-airport-and-astra-aerospace-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/newcastle-atsi",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/newcastle-lord-mayors-arts",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/newcastle-lord-mayors-inclusion-scholarship-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/nsw-aboriginal-housing-office-accommodation-grant",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/nsw-health-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/oliver-shaul",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/quota-club-of-lismore-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/rda-hunter-aviation-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/reg-russom-memorial",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/riverina-study-support-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/sandy-smith-memorial-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/sl-steel-work-health-safety-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/sonia-walkom-memorial-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/strategic-group-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/stylecraft-first-nation-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/together-today-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/trevor-drayton",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/varley-scholarships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/veolia-mulwaree-trust-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/walkom-education-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/walkom-real-estate-encouragement-scholarship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/william-galvin-memorial",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/woolfson-encouragement-award",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/zonta-club",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/scholarships/zonta-club-nepean-valley",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/australian-patisserie-academy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/bradfield-senior-college",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/design-centre-enmore",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fashion-design-studio",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/hunter-valley-hotel-academy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/iat",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/kurri-kurri-winery",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/emergency-management",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/national-centre-for-emergency-management",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/national-environment-centre",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sydney-wine-academy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/western-sydney-creative-centres",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/infrastructure-strategy/three-strategic-directions",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/infrastructure-strategy/three-strategic-directions/community",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/infrastructure-strategy/three-strategic-directions/learning-spaces",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/infrastructure-strategy/three-strategic-directions/portfolio",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/infrastructure-strategy/vision-for-infrastructure",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about-landing",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/accessibility",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/people",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/jobs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/jobs/aboriginal-employment",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate/annual-report",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/disability-inclusion-action-plan",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/diversity-inclusion-and-belonging-plan",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/environmental-sustainability",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate/corporate-governance",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/infrastructure-strategy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/infrastructure-strategy/nine-outcomes",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/multicultural-plan",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/reconciliation",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/strategic-plan",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/media-centre",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/articles",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/career-advice/change-my-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/career-advice/progress-my-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/career-advice/start-my-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/change-my-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/find-my-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/media",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/media/-/blogs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/progress-my-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/start-my-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/policies-procedures/child-protection",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/policies-procedures/governance-legal-and-risk",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/policies-procedures/higher-education",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/agency-information-guide",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/information-access",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate/design-standards",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/disclaimer",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/disclosure-log",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/policies-procedures/conducting-research-in-tafe",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/privacy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/about/policies-procedures/rights-responsibilities",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/student-rights-and-responsibilities",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/rights-responsibilities",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/rights-responsibilities/student-responsibilities-in-work-placement",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/use-of-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/why-tafe-digital/testimonials",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/tafe-nsw",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/why",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/why/vet-facts",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/product/not-found",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/view-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/equine",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/for-industry/apprenticeships-and-traineeships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses/apprenticeships-traineeships/apprenticeships-and-traineeships-for-employers",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses/apprenticeships-traineeships/pathway-to-apprenticeship",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses/certificates-i-iv",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses/diplomas",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fee-free-traineeships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/microskills",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/microskills/accounting-and-finance",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/micro-skills/electric-vehicles",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/microskills/small-business",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses/subsidised-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/school-students",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses/tvet",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/higher-education-short-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/our-degrees",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/jobtrainer",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/summer-skills",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/business-training/short-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/about",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/about/contact-us",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/about/faqs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/about/industry-engagement",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/alia",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/central-coast-local-health-district",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/cisco",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/family-and-community-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/harley-davidson",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/icc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/lendlease",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/montefiore",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/nrma",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/office-of-sport",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/pwc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/telstra",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/warrigal",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/case-studies/woolworths",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/financial",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/financial/government-funded-training",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/financial/government-funding-for-business",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/financial/training-costs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/for-industry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/for-industry/digital-lab",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/for-industry/employ-tafe-students",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/for-industry/nsw-skills-list",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/for-industry/skillspoints",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/industry/agskilled",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/industry/facilities-hire",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/resources/evolution-of-skills",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/business-administration",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/compliance",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/customer-service",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/emergency-management",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/financial-reforms",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/hr-training",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/ict",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/leadership-management",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/licensee",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/marketing",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/short-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise/training/training-needs-analysis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enterprise-report",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ilm",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/talent-first",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/childcare-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/Study/Support-services/childcare-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/facility-hire",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/launchpad",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/schools",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/schools/pathway-brochures",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate/supplier-resources-hub",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate/supplier-resources-hub/procurement-initiatives",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate/supplier-resources-hub/resource-library",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/corporate/supplier-resources-hub/transacting-with-tafe-nsw",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/aboriginal",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/adult-migrant-english-service",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/demo/short-courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/instituteofappliedtechnology",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/for-students",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/applying-enrolling",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/academic-calendar",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/important-dates",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/credit-pathways",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/recognition-credit-transfers",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/smart-skilled",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/applying-and-fees/entry-requirements",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/applying-and-fees/fees-and-payment",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/applying-and-fees/help-with-fees",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/fees",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fees",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/refunds-withdrawals",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/payment-funding/temporary-visa-holders",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/enrol/visa-holders",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/vet-student-loans",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-readiness",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/degrees/graduation",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mfa",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mfa/how-to-android",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mfa/how-to-authenticator",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mfa/how-to-ios",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/mfa/how-to-yubikey",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/carer-to-career",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/pathways/school-to-tafe",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/pathways/tafe-to-university",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/pathways/university-to-tafe",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/pathways/work-to-tafe",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/technology-support-for-students",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/student-travel-concessions",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/support/aboriginal-students",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/aboriginal-students",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/counselling-career-development-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/counselling-career-development-services/personal-counselling-wellbeing",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/disability-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/disability-services/neurodiversity",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/career-advice",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/counselling-career-development-services/educational-career-counselling",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/career-planning",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/language-communication",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/covid",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/faqs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/forms-faqs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/student-associations",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/support-and-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/jobs-connect",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/library-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/multicultural-support-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/safezone",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/study-support",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/why-tafe-digital",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/ways-to-study/virtual-classrooms",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/294441/TAFE-NSW-Student-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/294441/TAFE-NSW-Student-Guide.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/285049/2025-course-list.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/285049/2025-course-list.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/285049/tafe-nsw-international-student-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/285049/tafe-nsw-international-student-guide.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/168353/TAFE-NSW-HE-Degree-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/168353/TAFE-NSW-HE-Degree-Guide.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/TVET-Brochure.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/TVET-Brochure.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Student-Guide-Moodle.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Student-Guide-Moodle.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Learning-through-your-moodle.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Learning-through-your-moodle.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Submitting-your-assessments-and-receiving-feedback.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Submitting-your-assessments-and-receiving-feedback.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/60957/CAA-Online-Resources-flyer-A4-v10.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/60957/CAA-Online-Resources-flyer-A4-v10.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/197799/TAFE-NSW-Community-Services-courses.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/197799/TAFE-NSW-Community-Services-courses.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/PP-student-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/PP-student-guide.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/TAFE-NSW-Strategic-Plan-22-25.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/TAFE-NSW-Strategic-Plan-22-25.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/265330/STSD21-InfoSheet-Students-Version.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/265330/STSD21-InfoSheet-Students-Version.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+do+I+install+Office+365.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+do+I+install+Office+365.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Access+my+Email.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Access+my+Email.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How%2Bto%2BAccess%2Bmy%2BEmail.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How%2Bto%2BAccess%2Bmy%2BEmail.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+do+I+access+my+OneDrive.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+do+I+access+my+OneDrive.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How%2Bdo%2BI%2Baccess%2Bmy%2BOneDrive.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How%2Bdo%2BI%2Baccess%2Bmy%2BOneDrive.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Getting-Ready-for-Virtual-Classroom-Guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073829331/Getting-Ready-for-Virtual-Classroom-Guide.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/Student+Guide+-+Connected+Learning+Classroom+Expectations.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/Student+Guide+-+Connected+Learning+Classroom+Expectations.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Access+my+Student+&+Learner+Portal.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Access+my+Student+&+Learner+Portal.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Access+my+Student+%26+Learner+Portal.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Access+my+Student+%26+Learner+Portal.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Install+the+Adobe+Creative+Cloud+Suite.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/0/How+to+Install+the+Adobe+Creative+Cloud+Suite.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/datacentreacademy",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/types-courses/apprenticeships-traineeships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Withdrawal-Application-Form.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Withdrawal-Application-Form.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Enrolment-Adjustment-Change-of-Personal-Details.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Enrolment-Adjustment-Change-of-Personal-Details.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Student-Concession-Fee-Application-Form.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Student-Concession-Fee-Application-Form.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/197799/tafe-nsw-career-guide.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/197799/tafe-nsw-career-guide.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073802978/Deferral-Application-Form.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1073802978/Deferral-Application-Form.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Replacement-Testamur-Application-Form.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/553718618/Replacement-Testamur-Application-Form.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1074361837/Work-and-Development-Order-Exceptional-Circumstances-Form.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/1074361837/Work-and-Development-Order-Exceptional-Circumstances-Form.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/vet-student-loans-withdrawal-application-form.pdf",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/documents/60140/76288/vet-student-loans-withdrawal-application-form.pdf/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/buy-course-resources",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/why-tafe-digital/about-us",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/entry-requirements",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/how-to-enrol",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/payment-options-and-assistance",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/what-happens-when-i-enrol",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/getting-started/online-study-tips",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/study/courses/certificate-advanced-diploma",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/how-to-apply",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/digital/courses",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/study/support-services/counselling/career-development-services/educational-career-counselling",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/about",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/about/about-tafe-nsw",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/about/about-tafe-nsw/policies-procedures",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/about/about-tafe-nsw/terms-conditions",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/about/esos-framework",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/about/news",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/about/rights-responsibilities",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/course-search-results",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/esos-framework-tafe-nsw-policies-and-forms",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/faqs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/fees",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/how-to-apply/academic-calendar",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/how-to-apply/entry-requirements",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/how-to-apply/entry-requirements/academic-requirements",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/how-to-apply/entry-requirements/additional-requirements",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/how-to-apply/entry-requirements/english-requirements",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/how-to-apply/visa",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/orientation",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/pre-departure-and-arrival",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/scholarships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/enrol/support-services",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/midsemester",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/study/courses/degrees",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/study/courses/english",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/study/courses/pathways",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/study/student-guides/translated-international-student-guides",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/urgent-updates",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/global-engagement",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/global-engagement/industry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/global-engagement/partnerships",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/global-engagement/professional-development",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/global-engagement/study-tours",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/life-in-nsw",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/life-in-nsw/accommodation",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/life-in-nsw/health",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/life-in-nsw/safety",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/life-in-nsw/transport",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/life-in-nsw/working",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/international/why-tafe-nsw/teachers",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/student-services/international-student-support",
    component: component_45stubnO9MU04yTU
  }
]
import { defineStore } from 'pinia'
import useInternationalApi from '~/composables/international/useInternationalApi'

type Course = models.server.api.international.course.Course
type Package = models.server.api.international.packages.PackageResponse
type ApiResponse = models.server.api.international.ApiResponse

interface State {
  items: (Course | Package['_source'])[]
}

export const useIntCourseStore = defineStore('intCourse', {
  state: (): State => ({
    items: []
  }),

  getters: {
    getOne:
      (state) =>
      (courseId: string): Course | Package['_source'] | undefined => {
        return state.items.find(({ id }) => id === courseId)
      },
    courseExists: (state) => (courseId: string) => {
      return state.items.findIndex(({ id }) => id === courseId) !== -1
    }
  },

  actions: {
    async fetchOne(id: string | undefined) {
      const { getIntCourse } = useInternationalApi()

      if (!id) return

      // if the course already exists in state don't perform the API request
      if (this.courseExists(id)) return this.getOne(id)

      const { courseResult, error } = await getIntCourse(id)

      if (error.value) {
        throw createError({
          cause: error.value.cause,
          message: error.value.message,
          statusCode: error.value.statusCode
        })
      }

      if (!!(courseResult && Object.keys(courseResult).length)) {
        this.items.push(courseResult as Course)
        return courseResult
      } else {
        throw createError({
          statusCode: 404
        })
      }
    },
    async fetchOneByCourseId(id: string | undefined) {
      if (!id) return

      // if the course already exists in state don't perform the API request
      if (this.courseExists(id)) return this.getOne(id)

      const data = await $fetch<ApiResponse>(`/api/international/course`, {
        method: 'POST',
        body: {
          id: 'is-search-courses',
          params: {
            from: 0,
            size: 1,
            filters: [
              {
                field: 'id',
                value: id,
                last: true
              }
            ]
          }
        }
      })

      let courseResult

      if (data) {
        if (data?.hits?.hits.length < 1) {
          throw createError({
            statusCode: 404
          })
        } else {
          courseResult = data.hits.hits[0]._source
        }
      }

      if (!!(courseResult && Object.keys(courseResult).length)) {
        this.items.push(courseResult as Course)
        return courseResult
      } else {
        throw createError({
          statusCode: 404
        })
      }
    },
    async fetchOnePathway(id: string | undefined) {
      const { getPathway } = useInternationalApi()

      if (!id) return

      // if the course already exists in state don't perform the API request
      if (this.courseExists(id)) return this.getOne(id)

      const { pathwayResult, error } = await getPathway(id)

      if (error.value) {
        throw createError({
          cause: error.value.cause,
          message: error.value.message,
          statusCode: error.value.statusCode
        })
      }

      if (!!(pathwayResult && Object.keys(pathwayResult).length)) {
        this.items.push(pathwayResult as Package['_source'])
        return pathwayResult
      } else {
        throw createError({
          statusCode: 404
        })
      }
    },
    async fetchOnePathwayById(id: string | undefined) {
      if (!id) return

      // if the course already exists in state don't perform the API request
      if (this.courseExists(id)) return this.getOne(id)

      const data = await $fetch<ApiResponse>(`/api/international/package`, {
        method: 'POST',
        body: {
          id: 'is-search-courses',
          params: {
            from: 0,
            size: 1,
            filters: [
              {
                field: 'id',
                value: id,
                last: true
              }
            ]
          }
        }
      })

      let pathwayResult

      if (data) {
        if (data?.hits?.hits.length < 1) {
          throw createError({
            statusCode: 404
          })
        } else {
          pathwayResult = data.hits.hits[0]._source
        }
      }

      if (!!(pathwayResult && Object.keys(pathwayResult).length)) {
        this.items.push(pathwayResult as Package['_source'])
        return pathwayResult
      } else {
        throw createError({
          statusCode: 404
        })
      }
    },
    async fetchAdditionalCourse(ids: string[]) {
      const { getAdditionalCourse } = useInternationalApi()
      const getAdditionalCourseResult = ref([])

      const filteredCourseIds = ids.filter((id) => {
        if (!id) return false

        // if the course already exists in state don't perform the API request
        return !this.courseExists(id)
      })

      getAdditionalCourse(filteredCourseIds).then((result) => {
        result.forEach((courseResult) => {
          if (!!(courseResult && Object.keys(courseResult).length)) {
            this.items.push(courseResult as Course)
          }
        })
      })
    }
  }
})

import { camelCase } from 'lodash-es'
import { stringEmpty } from '~/utils/helpers'

type SitecoreLayoutRaw =
  models.server.api.sitecore.sitecoreLayoutService.SitecoreLayoutRaw
type NestedProp = models.server.api.sitecore.sitecoreLayoutService.NestedProp
type Placeholders =
  models.server.api.sitecore.sitecoreLayoutService.Placeholders
type ComponentPlaceholder =
  models.server.api.sitecore.sitecoreLayoutService.ComponentPlaceholder
type ComponentFields =
  models.server.api.sitecore.sitecoreLayoutService.ComponentFields
type Component = models.server.api.sitecore.sitecoreLayoutService.Component
type ComponentProp =
  models.server.api.sitecore.sitecoreLayoutService.ComponentProp

export const parseLayoutData = (rawData?: SitecoreLayoutRaw): unknown => {
  if (!rawData?.sitecore?.route?.fields) return undefined

  const route = rawData.sitecore.route
  const context = rawData.sitecore.context
  const routeFields = rawData.sitecore.route.fields
  const themeColor = routeFields.StandardPageThemeColour

  return {
    name: route.name,
    displayName: route.displayName,
    templateName: route.templateName,
    itemPath: context?.itemPath?.replace(/\/tafe-website\/home/i, ''),
    color: themeColor?.fields?.ColorName?.value,
    metadata: {
      title: getValue(routeFields.PageMetaTagTitle),
      forcedError: getValue(routeFields.FieldDoesNotExist),
      description: getValue(routeFields.PageMetaTagDescription),
      keywords: getList(routeFields.PageMetaTagKeywords)
    },
    placeholders: parsePlaceholders(route.placeholders)
  }
}

// nested and recursive, don't know the shape of the data
const flattenStructure = (
  fields?: Component | ComponentFields | ComponentProp
): undefined | unknown => {
  if (!fields) return undefined

  // if an array, recurse into each item
  if (Array.isArray(fields)) return fields.map((i) => flattenStructure(i))
  // recurse into each value
  else if (typeof fields === 'object') {
    return Object.entries(fields).reduce((o, [k, v]) => {
      const value = getNestedValue(v)
      return { ...o, [k]: flattenStructure(value) }
    }, {})
  }

  return fields
}

const getNestedValue = (val: ComponentProp) => {
  if (!val || typeof val !== 'object') return val

  const keys = Object.keys(val)
  const subKey = keys.length === 1 && keys[0] === 'value' && keys[0]
  return subKey ? val[subKey as keyof typeof val] : val
}

const getValue = (field: NestedProp) =>
  !field || !field.value ? undefined : field.value

const getList = (field?: NestedProp) => {
  if (!field) return []

  const value = getValue(field) as string
  return stringEmpty(value) ? [] : value.split(',').map((v) => v.trim())
}

const parsePlaceholders = (placeholders?: Placeholders) => {
  if (!placeholders) return undefined

  return Object.entries(placeholders).reduce(
    (o, [k, v]) => ({ ...o, [k]: parseComponents(v) }),
    {}
  )
}

const parseComponents = (components: ComponentPlaceholder[]) => {
  if (!components || !Array.isArray(components) || components.length === 0)
    return undefined

  return components.map((c) => ({
    componentName: camelCase(c.componentName),
    fields: flattenStructure(c.fields) as ComponentFields
  }))
}

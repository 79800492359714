import { defineStore } from 'pinia'

type Alert = models.stores.alert.Alert

interface State {
  alerts: Alert[]
}

export const useAlertStore = defineStore('alert', {
  state: (): State => ({
    alerts: []
  }),
  actions: {
    set(alert: Alert) {
      this.alerts.push(alert)
    },
    clear() {
      this.alerts.shift()
    },
    empty() {
      this.alerts = []
    }
  }
})

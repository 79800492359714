import { defineStore } from 'pinia'
import { useSitecoreGraphQueryStore } from '~/stores/sitecoreGraphQuery'

type Payload = models.server.api.graphQuery.globalComponents.Root

interface State {
  config: models.stores.siteConfig.Config
}

export const useIntSiteConfigStore = defineStore('intSiteConfig', {
  state: (): State => ({
    config: {}
  }),
  actions: {
    async fetch() {
      if (this.config && Object.keys(this.config).length) return

      const sitecoreGraphQuery = useSitecoreGraphQueryStore()
      const { data } = await sitecoreGraphQuery.fetch<Payload>(
        '41F2905D-8031-4778-A406-9527312635C8'
      )

      const { mapNavigation, parseBusinessRules, parseFeatureFlags } =
        useSitecoreGqlUtils()

      this.config = {
        footer: data?.footer,
        globalNav: mapNavigation(data?.globalNav?.children),
        businessRules: parseBusinessRules(data?.businessRules),
        featureFlags: parseFeatureFlags(data?.featuredFlag)
      }
    }
  }
})

import { defineStore } from 'pinia'

type Scrolled = models.stores.ui.Scrolled

interface State {
  showMobileNavModal: boolean
  scrolled: Scrolled
  footerOffset: number
  pageHeaderOffset: number
  appHeaderOffset: number
  isChatOpen: boolean
  isSearchFocused: boolean
}

export const useUiStore = defineStore('ui', {
  state: (): State => ({
    showMobileNavModal: false,
    scrolled: {
      isScrolled: false,
      value: 0
    },
    footerOffset: 0,
    pageHeaderOffset: 118,
    appHeaderOffset: 122,
    isChatOpen: false,
    isSearchFocused: false
  }),
  actions: {
    checkChatHours() {
      const date = new Date()
      const time = date.getHours() + date.getMinutes() / 100
      // If it is not Saturday or Sunday and is between the hours of 8am and 5:45pm,
      // then show the live chat button
      this.isChatOpen =
        date.getDay() !== 0 && // Sunday
        date.getDay() !== 6 && // Saturday
        time >= 8 && // 8am
        time <= 17.45
    },
    setScrolledStatus(payload: Scrolled) {
      this.scrolled = { ...this.scrolled, ...payload }
    },
    setSearchFocused(payload: boolean) {
      this.isSearchFocused = payload
    },
    setFooterOffset(payload: number) {
      this.footerOffset = payload
    },
    setPageHeaderOffset(payload: number) {
      this.pageHeaderOffset = payload
    }
  },
  getters: {
    getScrolled: (state) => state.scrolled,
    getFooterOffset: (state) => state.footerOffset
  }
})

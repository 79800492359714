<template>
  <div class="w-full print:hidden" :class="[`text-${theme}`, computedColour]">
    <div class="container overflow-hidden">
      <ul class="hidden md:flex text-sm">
        <!-- Home button always shown -->
        <li class="mr-2 flex items-center py-3">
          <nuxt-link :to="routeLink" class="flex items-center">
            <Icon name="IconHome" class="text-xl" title="Go to homepage" />
            <span class="sr-only">Go to homepage</span>
          </nuxt-link>
          <Icon name="IconChevronRight" class="ml-2 text-base" />
        </li>

        <li
          v-for="(item, i) in items"
          :key="i"
          class="mr-2 text-sm flex items-center py-3 whitespace-no-wrap"
        >
          <nuxt-link
            :to="item.to"
            exact
            class="flex no-underline items-center hover:underline"
          >
            <span class="flex-1 truncate">
              {{ item.text }}
            </span>
          </nuxt-link>
          <Icon
            v-if="notLastCrumb(i)"
            name="IconChevronRight"
            class="text-base ml-2"
          />
        </li>
      </ul>

      <!-- Mobile crumb -->
      <nuxt-link
        :to="backCrumb.to"
        class="flex items-center font-bold truncate md:hidden py-3"
      >
        <Icon name="IconChevronLeft" class="text-xl" />

        <span>Back to {{ backCrumb.text }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { stringEmpty } from '~/utils/helpers'

export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    colour: {
      type: String,
      default: 'blue'
    },
    colourClass: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'black'
    }
  },
  setup() {
    const { isInternational } = useTheme()
    return { isInternational }
  },
  computed: {
    backCrumb() {
      return (
        // Show the second last item (item before the current item) or just Home if that doesn't exist
        this.items[this.items.length - 2] || {
          text: this.isInternational ? 'International' : 'Home',
          to: { name: this.isInternational ? 'international' : 'index' }
        }
      )
    },
    computedColour() {
      return !stringEmpty(this.colourClass)
        ? this.colourClass
        : `bg-brand-${this.colour}-300`
    },
    routeLink() {
      return {
        name: this.isInternational ? 'international' : 'index'
      }
    }
  },
  methods: {
    notLastCrumb(index) {
      return index !== this.items.length - 1
    }
  }
}
</script>

<style lang="postcss" scoped>
/* Override exact active link styles in Nuxt config */
a.nuxt-link-active {
  @apply font-normal;
}
</style>

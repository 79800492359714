import { stringEmpty, tryParseJSON } from '~/utils/helpers'
import { camelCase } from 'lodash-es'

type GlobalNavChild =
  models.server.api.graphQuery.globalComponents.GlobalNavChild
type BusinessRules = models.server.api.graphQuery.globalComponents.BusinessRules
type FeatureFlags = models.server.api.graphQuery.globalComponents.FeatureFlags
type Rule = models.server.api.graphQuery.globalComponents.Rule
type RuleCollection = models.stores.siteConfig.RuleCollection
type GlobalNavLink = models.stores.siteConfig.GlobalNavLink
type FeatureFlagCollection = models.stores.siteConfig.FeatureFlagCollection

export default function () {
  function mapNavigation(
    items: GlobalNavChild[] | undefined,
    level = 0
  ): GlobalNavLink[] {
    if (items === undefined) return []

    return items.map((item) => {
      const link = extractLink(item, level)

      if (item.children && item.children) {
        link.children = mapNavigation(item.children, level + 1)
      }

      return link
    })
  }

  function parseBusinessRules(
    rules: BusinessRules | undefined
  ): Record<string, RuleCollection> {
    if (rules === undefined) return {}

    return Object.keys(rules).reduce(
      (o, level) => ({
        ...o,
        [camelCase(level)]: parseRules(
          rules[level as keyof BusinessRules]?.Rules || []
        )
      }),
      {}
    )
  }

  function parseFeatureFlags(
    flags: FeatureFlags | undefined
  ): FeatureFlagCollection {
    if (flags === undefined) return {}

    return (
      flags.children?.reduce(
        (o, flag) => ({
          ...o,
          [camelCase(flag.title.value)]: getFlagValue(flag.status.value)
        }),
        {}
      ) || {}
    )
  }

  return { mapNavigation, parseBusinessRules, parseFeatureFlags }
}

const getFlagValue = (status: string): boolean | null => {
  switch (status) {
    case 'On':
      return true
    case 'Off':
      return false
    default:
      return null
  }
}

const extractLink = (item: GlobalNavChild, level: number) => {
  let link: GlobalNavLink = {
    level,
    title: item?.title?.value,
    color: item?.color?.value
  }

  if (item?.link && !stringEmpty(item.link.value)) {
    link = {
      ...link,
      href: item.link.value,
      external: item.external?.value === '1'
    }
  }

  if (item.customKeys && !stringEmpty(item.customKeys.value)) {
    const customKey = tryParseJSON(item.customKeys.value || '')
    if (customKey) {
      link = {
        ...link,
        ...customKey
      }
    }
  }

  return link
}

const parseRules = (rules: Rule[]) =>
  rules.reduce(
    (o, r) => ({
      ...o,
      [camelCase(r.name)]: parseNumber(r.value)
    }),
    {}
  )

const parseNumber = (value: string) =>
  isNaN(Number(value)) ? value : Number(value)

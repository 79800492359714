import { cloneDeep } from 'lodash-es'
type Course = models.server.api.sitecore.course.Course

const courseValidationFields = [
  'learningOutcomes',
  'courseOutcomes',
  'entryRecommendations',
  'completionRequirements',
  'otherJobs',
  'previousStudy',
  'relatedStudy',
  'furtherStudy',
  'tags',
  'keywords',
  'workPlacement',
  'assessments',
  'otherRequirements',
  'courseUnits'
]

function set<Obj, K extends keyof Obj, V extends Obj[K]>(
  obj: Obj,
  key: K,
  value: V
) {
  obj[key] = value
}

export function validateCourseFields(course: Course | undefined) {
  if (!course) return {}

  const result = cloneDeep(course)

  courseValidationFields.forEach((f) => {
    const key = f as keyof Course
    if (Object.hasOwn(result, key) && !result[key]) {
      set(result, key, [])
    }
  })

  return result
}

<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: update the international site layout -->
<template>
  <div class="flex flex-col min-h-screen relative">
    <alert-banner class="print:hidden" />
    <alert-container class="print:hidden" />
    <int-app-header class="print:hidden" />
    <div id="e2e-content" class="flex-grow">
      <slot></slot>
    </div>
    <div class="relative bg-brand-grey-600 md:bg-transparent">
      <help-buttons :chat-status="chatFeatureFlag" />
    </div>
    <back-to-top-button v-if="scrolledValue > 100" />
    <int-app-footer class="print:hidden" />
  </div>
</template>
<script setup lang="ts">
import { useIntSiteConfigStore } from '~/stores/intSiteConfig'
import { useUiStore } from '~/stores/ui'
import useExternalWidget from '~/composables/useExternalWidget'

const intSiteConfigStore = useIntSiteConfigStore()
const uiStore = useUiStore()
const myChatWidget = useExternalWidget()

await intSiteConfigStore.fetch()
myChatWidget.configMyChat()

const chatFeatureFlag = computed(() => {
  return intSiteConfigStore.config?.featureFlags?.displayChatIcon
})

const scrolledValue = computed(() => {
  return uiStore.scrolled?.value || 0
})

provide('IsInternational', true)
</script>

import { Loader, type LoaderOptions } from '@googlemaps/js-api-loader'

export default defineNuxtPlugin(async () => {
  type LatLng = google.maps.LatLngLiteral
  const getLoader = (options: Partial<LoaderOptions> = {}) => {
    const runtimeConfig = useRuntimeConfig().public
    return new Loader({
      apiKey: runtimeConfig.googleSearchKey,
      version: 'weekly',
      ...options
    })
  }

  // create loader
  const loader = getLoader()

  // import libraries
  const { Map } = await loader.importLibrary('maps')
  const { AdvancedMarkerElement } = await loader.importLibrary('marker')
  const { Autocomplete } = await loader.importLibrary('places')
  const { Geocoder } = await loader.importLibrary('geocoding')

  const getLocation = (
    input: HTMLElement,
    latLng: LatLng,
    options: google.maps.MapOptions
  ) => {
    const map = new Map(input, options)
    return new AdvancedMarkerElement({
      map,
      position: latLng
    })
  }

  const getLocationBounds = (
    mapContainer: HTMLElement,
    options: google.maps.MapOptions,
    coordinates: LatLng[]
  ) => {
    const map = new Map(mapContainer, options)

    const createCustomMapMarker = document.createElement('img')
    createCustomMapMarker.style.width = '40px'
    createCustomMapMarker.style.height = '40px'
    createCustomMapMarker.src = new URL(
      '@/assets/icons/IconMapMarker.svg',
      import.meta.url
    ).href

    const coordinateKey = (coordinate: LatLng) =>
      `${coordinate.lat},${coordinate.lng}`
    const addedCoordinates = new Set()

    coordinates.forEach((coordinate) => {
      const key = coordinateKey(coordinate)
      if (!addedCoordinates.has(key)) {
        addedCoordinates.add(coordinateKey)
        new AdvancedMarkerElement({
          map,
          position: new google.maps.LatLng(coordinate.lat, coordinate.lng),
          content: createCustomMapMarker.cloneNode(true)
        })
      }
    })

    map.addListener('bounds_changed', () => {
      try {
        mapContainer.dispatchEvent(
          new CustomEvent('changed', {
            detail: {
              mapBound: map.getBounds()
            }
          })
        )
      } catch (error) {
        console.error('Error dispatching event', error)
      }
    })
  }

  const makeAutoComplete = (
    input: HTMLInputElement,
    options = { types: ['(cities)'] }
  ) => {
    const autocomplete = new Autocomplete(input, options)
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      input.dispatchEvent(new CustomEvent('changed', { detail: place }))
    })
  }

  const reverseGeocode = (lat: number, lng: number) => {
    const geocoder = new Geocoder()
    const latLng = new google.maps.LatLng(lat, lng)

    return geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results) {
          return results
        } else {
          console.error('Geocoder failed due to: ' + status)
          return status
        }
      }
    })
  }

  return {
    provide: {
      maps: {
        getLocation,
        makeAutoComplete,
        reverseGeocode,
        getLocationBounds
      }
    }
  }
})

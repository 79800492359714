<template>
  <div class="flex flex-col gap-3">
    <AccordionItem
      v-for="(item, key) in items"
      v-bind="item"
      :id="key"
      :key="`accordion_${key}`"
      :uuid="uuid"
      :color="color"
      :active="active"
      @toggle="toggleAccordion"
    />
  </div>
</template>

<script setup lang="ts">
import { type AccordionItemProps } from './AccordionItem.vue'

interface AccordionsProps {
  color?: string
  items?: AccordionItemProps[]
  initiallyOpen?: boolean
}

const props = withDefaults(defineProps<AccordionsProps>(), {
  color: '',
  items: () => [],
  initiallyOpen: false
})

const active = ref(props.initiallyOpen ? 0 : null)
const uuid = useId()

function toggleAccordion(index: number | null) {
  if (active.value === index) {
    active.value = null
  } else {
    active.value = index
  }
}
</script>

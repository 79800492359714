import { defineStore } from 'pinia'
import { formatISO } from 'date-fns'
import { useMainStore } from '~/stores/main'
import useHelpers from '~/composables/useHelpers'

type Offering = models.server.api.curriculum.offering.Offering
type SearchParams = models.server.api.curriculum.offeringSearch.SearchParams
type SearchResult = models.server.api.curriculum.offeringSearch.SearchResult

const { isExpired } = useHelpers()

interface State {
  items: Offering[]
  createdTracker: Record<string, string>
}

export interface SearchStatus {
  success: boolean
  searchResult?: SearchResult
}

export const useOfferingStore = defineStore('offering', {
  state: (): State => ({
    items: [],
    createdTracker: {}
  }),

  getters: {
    getOne: (state) => (offeringId: string) => {
      checkExpiry(state, offeringId)
      return state.items.find(({ id }) => id === offeringId) || {}
    },
    offeringExists: (state) => (offeringId: string) => {
      checkExpiry(state, offeringId)
      return state.items.findIndex(({ id }) => id === offeringId) !== -1
    },
    // todo: review usage, seems to be used for a property that isn't used anywhere
    // todo: check course index page for usage
    getAllByCourseId:
      (state) =>
      (courseId: string): Offering[] => {
        const uppercasedCourseId = courseId.toUpperCase()
        // due to how/where this collection is used, cannot apply expiry check as it
        return state.items.filter(
          (item) => item.courseId === uppercasedCourseId
        )
      }
  },

  actions: {
    addOne(offering: Offering) {
      if (!offering?.id) {
        return
      }

      if (!this.offeringExists(offering.id)) {
        this.items.push(offering)
      }
    },
    addMany(offerings: Offering[]) {
      if (!offerings) return

      for (const offering of offerings) {
        if (offering) {
          this.addOne(offering as Offering)
        }
      }
    },
    async fetchOne(id: string | undefined) {
      if (!id) return

      checkExpiry(this, id)

      if (this.offeringExists(id)) return this.getOne(id)

      const mainStore = useMainStore()
      const url = `${mainStore.origin}/api/offering/${id}`
      const offering = await $fetch<Offering>(url)

      if (!!(offering && Object.keys(offering).length)) {
        this.createdTracker[offering.id || id] = formatISO(new Date())
        this.items.push(offering)
      }

      return offering
    },
    async search(
      searchParams: SearchParams,
      abortController?: AbortController
    ): Promise<SearchStatus> {
      const status: SearchStatus = {
        success: false,
        searchResult: undefined
      }
      const mainStore = useMainStore()

      try {
        // Grab the API response and give the abort signal.
        const response = await $fetch<SearchResult>(
          `${mainStore.origin}/api/offering/_search`,
          {
            signal: abortController?.signal,
            params: searchParams,
            onRequestError(ctx) {
              // Treat an abort signal as NOT an error.
              if (ctx.error?.name === 'AbortError') {
                status.success = true
                return
              }
            },
            onResponseError(ctx) {
              // Treat an abort signal as NOT an error.
              if (ctx.error?.name === 'AbortError') {
                status.success = true
                return
              }
            }
          }
        ).catch((err) => null)

        if (!response) {
          return status
        }

        status.success = true
        status.searchResult = response

        // Cache all new offerings from the search result.
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.addMany(response.data.map((x) => x.attributes as Offering))
        }
      } catch (e) {}

      return status
    }
  }
})

const checkExpiry = (state: State, offeringId: string | undefined) => {
  if (offeringId == null) return

  if (isExpired(state.createdTracker[offeringId])) {
    delete state.createdTracker[offeringId]
    const index = state.items.findIndex(({ id }) => id === offeringId)
    if (index !== -1) state.items.splice(index, 1)
  }
}

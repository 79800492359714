import { addDays } from 'date-fns'
import { defineStore } from 'pinia'
import { useAlertStore } from '~/stores/alert'
type Step = models.stores.studySeeker.Step
type Answer = models.stores.studySeeker.Answer
interface State {
  steps: Array<Step>
  answers: Record<string, Answer[]>
  currentStepId: string
  matches: Record<string, Record<string, string[]>>
  filters: string[]
  expiryDate: Date
  showMobileResultsPanel: boolean
}

const RELEVANT_MATCH_COUNT = 4

const loadStudySeekerQuestions = () =>
  import('@/data/study-seeker-questions.json')

export const useStudySeekerStore = defineStore('studySeeker', {
  state: (): State => ({
    steps: [],
    answers: {},
    currentStepId: 'start',
    matches: {},
    // Filters should be a list of question IDs
    // TODO: Make this list dynamic
    filters: [
      'hobbies',
      'activities',
      'skills',
      'instruction',
      'learningEnvironment',
      'learningActivity',
      'environment',
      'workplace',
      'physicality',
      'coworkers',
      'customers',
      'schedule'
    ],
    expiryDate: new Date(),
    showMobileResultsPanel: false
  }),
  actions: {
    async fetchAll() {
      const data = await loadStudySeekerQuestions()
      this.steps = data.default
      return data.default
    },
    async addMatch(id: string, filterKey: string, tag: string, mq: string) {
      const prevLength = this.courseAreaMatches(id).length
      // Check id object exists in matches
      if (id in this.matches) {
        // filterKey array exists in id object
        if (filterKey in this.matches[id]) {
          // push tag name to array
          this.matches[id][filterKey].push(tag)
        } else {
          // filterKey array doesn't exist within id, create array and push tag
          this.matches[id][filterKey] = []
          this.matches[id][filterKey].push(tag)
        }
      } else {
        // id object and filterKey array don't exist, create object and array and push tag
        this.matches[id] = {}
        this.matches[id][filterKey] = []
        this.matches[id][filterKey].push(tag)
      }

      if (
        (mq === 'xs' || mq === 'sm') &&
        prevLength < RELEVANT_MATCH_COUNT &&
        this.courseAreaMatches(id).length >= RELEVANT_MATCH_COUNT
      ) {
        const alertStore = useAlertStore()
        alertStore.set({
          msg: 'Course Area Matched',
          id,
          icon: 'IconCheckmark'
        })
      }
    },
    removeMatch(id: string, filterKey: string, tag: string) {
      const index = this.matches[id][filterKey].findIndex(
        (matchedTag: string) => {
          return matchedTag === tag
        }
      )
      this.matches[id][filterKey].splice(index, 1)
    },
    setAll(payload: Array<Step>) {
      this.steps = payload
    },
    setCurrentStepId(payload: string) {
      this.currentStepId = payload
    },
    setAnswer(id: string, value: Answer[]) {
      this.answers[id] = value
    },
    setAnswers(payload: Record<string, Answer[]>) {
      this.answers = payload
    },
    resetAnswers() {
      this.answers = {}
      this.currentStepId = 'start'
    },
    clearMatched() {
      this.matches = {}
    },
    setExpiryDate() {
      // Set new expiry date 1 day from now
      const expiry = addDays(new Date(), 1)
      this.expiryDate = expiry
    }
  },
  getters: {
    isExpired: (state) => new Date() > new Date(state.expiryDate),
    getCurrentStep: (state) =>
      state.steps.find((step) => step.id === state.currentStepId),
    getStepById: (state) => (stepId: string) =>
      state.steps.find((step) => step.id === stepId),
    getStepIndexById: (state) => (stepId: string) =>
      state.steps.findIndex((step) => step.id === stepId),
    getNextStepIdByCurrentIndex: (state) => (index: number) =>
      state.steps[index + 1].id,
    getPreviousStepIdByCurrentIndex: (state) => (index: number) =>
      state.steps[index - 1].id,
    getTotalSteps: (state) => state.steps.length,
    isLastStep: (state) =>
      state.steps.findIndex((step) => step.id === state.currentStepId) ===
      state.steps.length - 1,
    isFirstStep: (state) =>
      state.steps.findIndex((step) => step.id === state.currentStepId) === 0,
    getAnswer: (state) => (stepId: string) => state.answers[stepId],
    courseAreaMatches: (state) => (name: string) => {
      // If no matches for course area, just return an empty array
      if (!state.matches[name]) {
        return [] as string[]
      }

      // Create a flattened array of all course area matches
      return Object.keys(state.matches[name]).reduce((acc: string[], key) => {
        acc = [...acc, ...state.matches[name][key]]
        return acc
      }, [])
    },
    courseAreaMatchesLength: (state: State) => (name: string) => {
      return state.matches[name]?.length || 0
    },
    relevantMatchesCount(state): number {
      // Return a count of all course areas that have a match count higher than relevant match count
      return Object.keys(state.matches).reduce((acc, key) => {
        if (
          (this.courseAreaMatches(key)?.length || 0) >= RELEVANT_MATCH_COUNT
        ) {
          acc += 1
        }
        return acc
      }, 0)
    }
  },
  persist: { storage: persistedState.localStorage }
})

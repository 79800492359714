import { defineStore } from 'pinia'

import useFrontDoorApi from '~/composables/useFrontDoorApi'
import useHelpers from '~/composables/useHelpers'
import useSitecoreLayout from '~/composables/sitecore-layout/useSitecoreLayout'
import { parseLayoutData } from '~/server/utils/sitecoreLayoutServiceMapping'
import { useSitecoreGraphQueryStore } from '~/stores/sitecoreGraphQuery'

type SitecoreLayoutRaw =
  models.server.api.sitecore.sitecoreLayoutService.SitecoreLayoutRaw
type SitecoreLayoutContent =
  models.stores.sitecoreLayoutPage.SitecoreLayoutContent
type LayoutDataSet = models.stores.sitecoreLayoutPage.LayoutDataSet

interface State {
  content: Record<string, LayoutDataSet>
}

export const useSitecoreLayoutPageStore = defineStore('sitecoreLayoutPage', {
  state: (): State => ({
    content: {}
  }),

  getters: {
    getLayoutContent: (state) => (path: string) =>
      Object.hasOwn(state.content, path) ? state.content[path] : undefined
  },

  actions: {
    async fetch(path: string) {
      const app = useNuxtApp()
      const { stringEmpty } = useHelpers()

      if (stringEmpty(path)) return

      if (Object.hasOwn(this.content, path)) return this.content[path]

      const { fetch } = useFrontDoorApi()

      const payload = (await fetch<SitecoreLayoutRaw, unknown>(
        `/api/sitecore-layout?path=${path}`,
        `/api/layout/render?item=/sitecore/content/tafe-website/home/${path}`,
        (content) => parseLayoutData(content)
      )) as SitecoreLayoutContent

      // Extra fetch for breadcrumbs
      const sitecoreGraphQuery = useSitecoreGraphQueryStore()
      const formattedPath = path.replace(/-/g, ' ')
      let trimmedPath: string | undefined
      try {
        const { data } = await app.runWithContext(() =>
          sitecoreGraphQuery.fetch<Payload>(
            '9094915B-AD4C-432C-A121-F07F4CCD926A',
            {
              queryParameter1: `/sitecore/content/Tafe Website/Home/${formattedPath}`
            }
          )
        )

        trimmedPath = data.getItemPath?.path?.replace(
          /^\/sitecore\/content\/Tafe Website\/Home/,
          ''
        )
      } catch (e) {
        console.error('error fetching breadcrumbs graph query', e)
      }

      if (payload) {
        const { mapComponents } = useSitecoreLayout()

        const page = {
          name: payload?.name,
          displayName: payload?.displayName,
          templateName: payload?.templateName,
          itemPath: trimmedPath || payload?.itemPath,
          color: payload?.color || 'blue',
          metadata: payload?.metadata
        }

        const shared = {
          color: payload?.color || 'blue'
        }

        const dataSet = {
          page,
          shared,
          raw: payload,
          components: mapComponents(payload?.placeholders?.main, shared)
        }

        this.content[path] = dataSet

        return dataSet
      }
    }
  }
})

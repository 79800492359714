import { defineStore } from 'pinia'
import useFrontDoorApi from '~/composables/useFrontDoorApi'

type Campus = models.server.api.sitecore.campus.Campus

type CampusData = Campus[] | { campuses: Campus[] } | null

export const useCampusStore = defineStore('campus', () => {
  const app = useNuxtApp()
  const flags = reactive({ setAll: false })
  const items = ref<Campus[]>([])

  const getOne = computed(() => (campusId?: string) => {
    if (!campusId) return

    const index = items.value.findIndex(({ id }) => id === campusId)

    return items.value[index]
  })

  async function fetchAll() {
    if (!flags.setAll) {
      const { fetch } = useFrontDoorApi()
      const result = await app.runWithContext(() =>
        fetch<CampusData, Campus[] | undefined>(
          '/api/campus',
          '/api/ssc/e2e.campuses/campusgetall',
          (data: CampusData) => (Array.isArray(data) ? data : data?.campuses)
        )
      )
      items.value = result || []
      flags.setAll = true
    }
  }

  async function fetch(id?: string) {
    if (!id) return

    if (campusExists(id)) return getOne.value(id)

    const { fetch } = useFrontDoorApi()
    const campus = await app.runWithContext(() =>
      fetch<Campus>(`/api/campus/${id}`, `/api/ssc/e2e.campuses/campus/${id}`)
    )
    if (campus) items.value.push(campus)

    return campus
  }

  async function fetchAllMapped() {
    try {
      await app.runWithContext(() => fetchAll())

      const mapped: Array<{ label: string; value: string }> | undefined =
        items.value.map((i) => ({
          value: i.id || '',
          label: i.name || '',
          id: i.id || ''
        }))
      return mapped
    } catch (e) {
      return undefined
    }
  }

  function campusExists(campusId: string) {
    return items.value.findIndex(({ id }) => id === campusId) !== -1
  }

  return {
    items,
    getOne,
    fetch,
    fetchAll,
    fetchAllMapped,
    campusExists
  }
})
